import React from 'react'
import { Link } from 'gatsby'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'
import styles from './full-page-card.module.scss'
const link = Link

class FullPageCard extends React.Component {
  constructor () {
    super()

    this.state = {
      animated: false,
      windowHeight : 750
    }
  }

  componentDidMount () {
    setTimeout(() => this.setState({ animated: true }), 1000)
  }

  render () {
    // gets used as link text/condition
    const { content = '', excerpt = '' } = this.props
    const { animated } = this.state

    return (
      <ScrollMonitoringWrapper isInViewportEvent={() => this.setState({ animated: true })}>
        <div className={`${styles.fullPageCard} ${animated && styles.animated}`}>
          <div className="container">
            <div className={styles.content}>
                <div dangerouslySetInnerHTML={{ __html: content }} />
                { excerpt && <Link className="btn" to="/">{excerpt.replace('<p>', '').replace('</p>', '')}</Link> }
            </div>
          </div>
        </div>
      </ScrollMonitoringWrapper>
    )
  }
}

export default FullPageCard
