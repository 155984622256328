import React from 'react'
import Layout from '../../components/layout'
import FullPageCard from '../../components/molecules/FullPageCard'
import styles from './coming-soon.module.scss'

export default ({ pageContext }) =>
  <Layout>
    <div>
      <FullPageCard content={pageContext.content} excerpt={pageContext.excerpt} />
    </div>
  </Layout>
